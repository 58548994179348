import React, { FunctionComponent } from "react"

import MainHeader from "../modules/main-header";
import WorkFlow from "../modules/workflow";
import ProjectList from "../modules/project-list";
import DeveloperList from "../modules/developer-list";
import StackList from "../modules/stack-list";
import Contact from "../modules/contact";
import Layout from "../components/layout";
import Footer from "../components/footer";

const IndexPage : FunctionComponent = () => (
    <Layout>
        <MainHeader />
        <ProjectList />
        <WorkFlow />
        <DeveloperList />
        <StackList />
        <Contact />
        <Footer />
    </Layout>
)

export default IndexPage;
